.btn-transfer i{
    line-height: 19px;
    margin-right: 8px;
}
.color-white{
    color: white;
}
.transfer .payment-method{
    width: 100%;
    display: flex;
}
.transfer .payment-method .item{
    text-align: center;
    border-radius: 4px;
    width: 50% !important;
    padding: inherit;
}
.otp-box{
    display: flex;
}
.otp-box>input, .boxAuthentication .otp-box>input.md-input{
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.otp-box>button{
    height: 45px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.telegram-auth{
    position: absolute;
    top: 37px;
    right: 30px;
    font-size: 18px;
    color: #00cd00;
}
.social-contact img{
    margin: 0 4px;
}
.mobile-version .binary_wallet .content {
    line-height: 40px;
}
.mask-close{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}
.notification-dropdown .scroll-area {
    overflow-y: scroll !important;
}


.mainLeaderboard[data-v-12d9d170] {
  max-width: 1205px;
  margin-top: 5px;
}
.notification-page-title[data-v-12d9d170] {
    font-size: 38px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    padding-bottom: 18px;
    border-bottom: 1px solid #4A3C6F;
    margin-bottom: 0;
}
.notification-page-readall[data-v-12d9d170] {
    cursor: pointer;
    margin-left: 10px;
    height: 21px;
}
.mobile-version .binary_wallet .content>.row{
    display: block;
    width: 85%;
}
.mobile-version .binary_wallet .content>.row button{
    margin-bottom: 8px;
}
.btn-qDeposit{
    padding: 0 8px;
}
.highlight-text{
    font-size: 18px !important;
    font-weight: bold;
    text-shadow: 0 0 5px #ffd400bd;
    line-height: 28px;
}
.btn-copy{
    margin-left: 8px;
    font-size: 14px;
    border: 1px solid #63e1e4;
    padding: 4px;
    border-radius: 11px;
    box-shadow: 0 0 5px 0px #00afb5;
    background: #00c2c7;
    color: black;
    cursor: pointer;
}
.deposit-change-font{
    font-size: 16px !important;
    line-height: 22px;
    margin-bottom: 10px !important;
}
