.sticky-footer {
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 99;
    /* height: 10px; */
}

.sticky-footer .menu {
    flex-shrink: 0;
    display: flex;
    padding: 10px 0;
    background: #1e2834;
    color: #fff;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
}

.sticky-footer li.item[data-v-6a96ae2b] {
    width: 200px;
    padding: 10px 18px !important;
    text-align: center;
}

.sticky-footer .menu  .item.border-right {
    border-color: #4e4e5d !important;
}